import { Col, Divider, Row, Steps, Typography } from 'antd'
import React, { FC } from 'react'
import styled from 'styled-components'
import ActivityFeedImage from '../components/image/activity-feed-image'
import CatalogImage from '../components/image/catalog-image'
import CSVImage from '../components/image/csv-image'
import DataONEImage from '../components/image/dataone-image'
import ProjectsImage from '../components/image/projects-image'
import Layout from '../components/layout'
import InfoPopover from '../components/popover/InfoPopover'
import PermissionsContent from '../components/popover/PermissionsContent'
import ProjectsContent from '../components/popover/ProjectsContent'
import SEO from '../components/seo'

const { Text } = Typography
const { Step } = Steps

const Title = styled.h1`
  margin-bottom: 0.2em;
`

const Content = styled.div`
  margin-top: 1.5em;
  margin-bottom: 0.2em;

  font-size: 1.2em;
`

const LifeCycleTitle = styled.h3`
  text-align: center;
  line-height: 2em;
  margin-top: 1em;
`

const ShadowDiv = styled.div`
  box-shadow: 3px 3px 14px 0px rgba(224, 224, 224, 1);
  padding: 5px;
`

const HowItWorks: FC = () => (
  <Layout showHome={true}>
    <SEO title="How it works" />
    <Title>How it works</Title>
    <Content>
      <Text>
        Scientific data management is a <strong>process</strong> of managing the
        flow of information from data creation to its use, reuse, and
        transformation.
        <p></p>
        The Research Workspace is designed to help program managers meet their{' '}
        <strong>data management requirements.</strong>
      </Text>
      <LifeCycleTitle>The scientific data life-cycle</LifeCycleTitle>
      <div>
        <Steps progressDot current={5}>
          <Step title="Collect" description="Track data ingestion" />
          <Step
            title="Store"
            description="Devise standards for organizing datasets"
          />
          <Step title="Analyze" description="Run scripts against your data" />
          <Step
            title="Preserve"
            description="Submit datasets to long-term data archives"
          />
          <Step
            title="Publish"
            description="Obtain DOIs and share research findings"
          />
        </Steps>
      </div>
      <Divider />
      <Row>
        <Col span={12}>
          <div style={{ marginTop: 50 }}>
            <h3>Collect</h3>
            <p>
              Assemble your research team and track progress as they share data.
            </p>
          </div>
        </Col>
        <Col span={12}>
          <ShadowDiv style={{ maxWidth: `250px`, marginBottom: `1.45rem` }}>
            {/* image from: https://researchworkspace.com/project/4680/folder/1645604/2012-2016-summer-surveys */}
            <ActivityFeedImage />
          </ShadowDiv>
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <div style={{ marginTop: 40, marginLeft: 5 }}>
            <h3>Store</h3>
            <p>
              Organize your data into folders and
              <InfoPopover content={ProjectsContent} title="What are projects?">
                &nbsp;projects
              </InfoPopover>
              , then control who has
              <InfoPopover content={PermissionsContent} title="Permissions">
                &nbsp;access
              </InfoPopover>
              .
            </p>
          </div>
        </Col>
        <Col span={12}>
          <ShadowDiv style={{ maxWidth: `500px`, marginBottom: `1.45rem` }}>
            <ProjectsImage />
          </ShadowDiv>
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <div style={{ marginTop: 50 }}>
            <h3>Analyze</h3>
            <p>
              Review your team&apos;s findings, run scripts on your data, and
              generate new research.
            </p>
          </div>
        </Col>
        <Col span={12}>
          <ShadowDiv style={{ maxWidth: `500px`, marginBottom: `1.45rem` }}>
            {/* <NotebookGalleryImage />
             */}

            <CSVImage />
          </ShadowDiv>
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <div style={{ marginTop: 40, marginLeft: 5 }}>
            <h3>Preserve</h3>
            <p>
              Obtain DOIs (digital object identifiers) and submit data to
              repositories for long-term storage.
            </p>
          </div>
        </Col>
        <Col span={12}>
          <ShadowDiv style={{ maxWidth: `500px`, marginBottom: `1.45rem` }}>
            <DataONEImage />
          </ShadowDiv>
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <div style={{ marginTop: 40, marginLeft: 5 }}>
            <h3>Publish</h3>
            <p>
              Highlight your research in a data catalog to showcase your
              research program&apos;s efforts.
            </p>
          </div>
        </Col>
        <Col span={12}>
          <ShadowDiv style={{ maxWidth: `500px`, marginBottom: `1.45rem` }}>
            <CatalogImage />
          </ShadowDiv>
        </Col>
      </Row>
    </Content>
  </Layout>
)

export default HowItWorks
