import React from 'react'

export default (
  <div>
    <p>
      Permissions can be controlled on a per-user basis, or data can be made
      public.
    </p>
  </div>
)
