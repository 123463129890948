import React from 'react'

export default (
  <div>
    <p>Projects are containers for your datasets and scientific data.</p>
    <p>
      Files reside in <strong>folders</strong> within projects.
    </p>
  </div>
)
