import { Popover } from 'antd'
import React, { FC, ReactNode } from 'react'
import styled from 'styled-components'

const HoverLink = styled.strong`
  color: #7d6dfd;
  cursor: help;
`

export interface Props {
  title: string
  content: ReactNode
}

const InfoPopover: FC<Props> = ({ title, content, children }) => {
  return (
    <Popover content={content} title={title}>
      <HoverLink>{children}</HoverLink>
    </Popover>
  )
}
export default InfoPopover
